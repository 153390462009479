import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { approveStudent } from "../../../../redux/slices/orderSlice";

const Verified = () => {
  const { email,id} = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (email && id) {
      dispatch(approveStudent(email,id));
    }
  }, [email,id]);

  return (
    <div className="container my-4">
      <div className="d-flex flex-column justify-content-start align-items-center auth px-0 w-100">
        <Row className="w-100 mx-0">
          <Col lg={12} md={12} sm={12} className="mx-auto">
            <div className="auth-form-light text-left py-5 px-4 px-sm-5">
              <div className="brand-logo d-flex">
                <img
                  className="mx-auto"
                  src={require("../../../../assets/images/logo.png")}
                  alt="logo"
                />
              </div>
              {/* <h4 className="login_heading mt-5">Hello! there, {greeting}</h4> */}

              <p style={{ fontSize: "23px", color: "#be9528" }}>
                <b>
                  <center class="main_title">Email Verified</center>
                </b>
              </p>
              <p class="desc_text text-center">
                Your Email is Verified. You can now close the browser.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Verified;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const birthdaySlice = createSlice({
  name: "birthday",
  initialState: {
    birthday: {},
    birthdayList: [],
    refetch: false,
  },
  reducers: {
    getAllBirthdaySuccess: (state, action) => {
      state.birthdayList = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getBirthdayDetailSuccess: (state, action) => {
      state.birthday = action.payload
    }
  },
});

export const { getAllBirthdaySuccess, getBirthdayDetailSuccess } = birthdaySlice.actions;

export const getAllBirthday = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/birthday/list/v1`;
    const res = await axios.get(url, {
      headers: { Authorization: localStorage.getItem("sgtoken") },
    });
    dispatch(getAllBirthdaySuccess(res.data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};
export const getBirthdayDetail = (id, setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/birthday/details/${id}/v1`;
    const res = await axios.get(url, {
      headers: { Authorization: localStorage.getItem("sgtoken") },
    });
    dispatch(getBirthdayDetailSuccess(res.data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export default birthdaySlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const userSlice = createSlice({
  name: "user",
  initialState: {
    users: [],
    user: {},
    admin: {},
    isUserUpdate: false,
  },
  reducers: {
    getAllUsersSuccess: (state, action) => {
      state.users = action.payload;
    },
    getUserDetailSuccess: (state, action) => {
      state.user = action.payload;
    },
    getAdminDetailSuccess: (state, action) => {
      state.admin = action.payload;
    },
    isUserUpdate: (state, action) => {
      state.isUserUpdate = action.payload;
    },
  },
});

export const {
  getAllUsersSuccess,
  getUserDetailSuccess,
  getAdminDetailSuccess,
  isUserUpdate,
} = userSlice.actions;

export const getAllUsers = (setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/get/all/users/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAllUsersSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const getUserDetail = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/admin/get/user/detials/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getUserDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const getAdminDetail = (setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/get/admin/profile/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAdminDetailSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const updateUserStatus = (id, setLoading) => async (dispatch) => {
  try {
    dispatch(isUserUpdate(false));
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/change/status/${id}/v1`;
    const res = await axios.put(
      url,
      {},
      {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      }
    );
    dispatch(isUserUpdate(true));
  } catch (error) {
    console.log(error);
  }
};

export default userSlice.reducer;

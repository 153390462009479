import React, { Suspense, lazy, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import Spinner from "../app/views/shared/Spinner";
import { useDispatch } from "react-redux";
import { getAdminDetail } from "../redux/slices/userSlice";
import Terms from "./views/pages/other/Terms";
import Privacy from "./views/pages/other/Privacy";
import Support from "./views/pages/other/Support";
import Verified from "./views/pages/other/Verified";
import AdminSetting from "./views/pages/AdminSetting";

const Dashboard = lazy(() => import("./views/pages/dashboard/Dashboard"));
const Profile = lazy(() => import("./views/pages/Profile"));
const Error404 = lazy(() => import("./views/pages/error/Error404"));
const Error500 = lazy(() => import("./views/pages/error/Error500"));
const Auth = lazy(() => import("./views/pages/auth/Auth"));
const SubAdmin = lazy(() => import("./views/pages/subadmin/SubAdmin"));
const SubAdminCreate = lazy(() =>
  import("./views/pages/subadmin/SubAdminCreate")
);
const SubAdminUpdate = lazy(() =>
  import("./views/pages/subadmin/SubAdminUpdate")
);
const Users = lazy(() => import("./views/pages/user/Users"));
const Category = lazy(() => import("./views/pages/Category"));
const CategoryDetail = lazy(() => import("./views/pages/CategoryDetail"));
const Location = lazy(() => import("./views/pages/Location"));
const AppSetting = lazy(() => import("./views/pages/AppSetting"));
const UserDetail = lazy(() => import("./views/pages/user/UserDetail"));
const Rentals = lazy(() => import("./views/pages/rentals/Rentals"));
const RentalDetail = lazy(() => import("./views/pages/rentals/RentalDetail"));
const RentalCreate = lazy(() => import("./views/pages/rentals/RentalCreate"));
const RentalUpdate = lazy(() => import("./views/pages/rentals/RentalUpdate"));
const Trainers = lazy(() => import("./views/pages/trainer/Trainers"));
const TrainerCreate = lazy(() => import("./views/pages/trainer/TrainerCreate"));
const TrainerUpdate = lazy(() => import("./views/pages/trainer/TrainerUpdate"));
const TrainerDetail = lazy(() => import("./views/pages/trainer/TrainerDetail"));
const Orders = lazy(() => import("./views/pages/order/Orders"));
const OrderDetails = lazy(() => import("./views/pages/order/OrderDetails"));
const DiscountBooking = lazy(() =>
  import("./views/pages/order/DiscountBooking")
);
const DiscountBookingDetails = lazy(() =>
  import("./views/pages/order/DiscountBookingDetails")
);
const Services = lazy(() => import("./views/pages/service/Services"));
const ServiceCreate = lazy(() => import("./views/pages/service/ServiceCreate"));
const ServiceUpdate = lazy(() => import("./views/pages/service/ServiceUpdate"));
const ServiceDetail = lazy(() => import("./views/pages/service/ServiceDetail"));
const Discounts = lazy(() => import("./views/pages/discount/Discounts"));
const DiscountCreate = lazy(() =>
  import("./views/pages/discount/DiscountCreate")
);
const DiscountUpdate = lazy(() =>
  import("./views/pages/discount/DiscountUpdate")
);
const DiscountDetail = lazy(() =>
  import("./views/pages/discount/DiscountDetail")
);
const Queries = lazy(() => import("./views/pages/query/Queries"));
const QueryDetail = lazy(() => import("./views/pages/query/QueryDetail"));
const Birthday = lazy(() => import("./views/pages/birthday/Birthday"));
const BirthdayDetail = lazy(() =>
  import("./views/pages/birthday/BirthdayDetail")
);
const Gifts = lazy(() => import("./views/pages/gift/Gifts"));
const GiftCreate = lazy(() =>
  import("./views/pages/gift/GiftCreate")
);
const GiftUpdate = lazy(() =>
  import("./views/pages/gift/GiftUpdate")
);
const GiftUse = lazy(() => import("./views/pages/gift/GiftUse"));
const GiftUseDetail = lazy(() => import("./views/pages/gift/GiftUseDetail"));

const SecureRoute = ({ redirectPath = "/login", children }) => {
  // !localStorage.getItem("sgtoken") ? <Navigate to={redirectPath} /> : children;
  if (!localStorage.getItem("sgtoken")) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

const AppRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("sgtoken");

  useEffect(() => {
    if (token) {
      dispatch(getAdminDetail(setLoading));
    }
  }, [location, token]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/login" element={<Auth />} />
        <Route path="/forgot_password" element={<Auth />} />
        <Route path="/forgot_password/otp" element={<Auth />} />
        <Route path="/reset_password" element={<Auth />} />
        <Route
          path="/change_password"
          element={
            <SecureRoute>
              <Auth />
            </SecureRoute>
          }
        />
        <Route
          path="/"
          element={
            <SecureRoute>
              <Dashboard />
            </SecureRoute>
          }
        />
        <Route
          path="/sub-admin"
          element={
            <SecureRoute>
              <SubAdmin />
            </SecureRoute>
          }
        />
        <Route
          path="/sub-admin/create"
          element={
            <SecureRoute>
              <SubAdminCreate />
            </SecureRoute>
          }
        />
        <Route
          path="/sub-admin/:id/update"
          element={
            <SecureRoute>
              <SubAdminUpdate />
            </SecureRoute>
          }
        />
        <Route
          path="/users"
          element={
            <SecureRoute>
              <Users />
            </SecureRoute>
          }
        />
        <Route
          path="/user/:id"
          element={
            <SecureRoute>
              <UserDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/categories"
          element={
            <SecureRoute>
              <Category />
            </SecureRoute>
          }
        />
        <Route
          path="/category/:id"
          element={
            <SecureRoute>
              <CategoryDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/location"
          element={
            <SecureRoute>
              <Location />
            </SecureRoute>
          }
        />
        <Route
          path="/rentals"
          element={
            <SecureRoute>
              <Rentals />
            </SecureRoute>
          }
        />
        <Route
          path="/rental/:id"
          element={
            <SecureRoute>
              <RentalDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/rental/create"
          element={
            <SecureRoute>
              <RentalCreate />
            </SecureRoute>
          }
        />
        <Route
          path="/rental/:id/update"
          element={
            <SecureRoute>
              <RentalUpdate />
            </SecureRoute>
          }
        />
        <Route
          path="/trainer"
          element={
            <SecureRoute>
              <Trainers />
            </SecureRoute>
          }
        />
        <Route
          path="/trainer/create"
          element={
            <SecureRoute>
              <TrainerCreate />
            </SecureRoute>
          }
        />
        <Route
          path="/trainer/:id/update"
          element={
            <SecureRoute>
              <TrainerUpdate />
            </SecureRoute>
          }
        />
        <Route
          path="/trainer/:id"
          element={
            <SecureRoute>
              <TrainerDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/bookings/program"
          element={
            <SecureRoute>
              <Orders />
            </SecureRoute>
          }
        />
        <Route
          path="/bookings/program/:id"
          element={
            <SecureRoute>
              <OrderDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/bookings/discount"
          element={
            <SecureRoute>
              <DiscountBooking />
            </SecureRoute>
          }
        />
        <Route
          path="/bookings/discount/:id"
          element={
            <SecureRoute>
              <DiscountBookingDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/services"
          element={
            <SecureRoute>
              <Services />
            </SecureRoute>
          }
        />
        <Route
          path="/service/create"
          element={
            <SecureRoute>
              <ServiceCreate />
            </SecureRoute>
          }
        />
        <Route
          path="/service/:id"
          element={
            <SecureRoute>
              <ServiceDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/service/:id/update"
          element={
            <SecureRoute>
              <ServiceUpdate />
            </SecureRoute>
          }
        />
        <Route
          path="/discounts"
          element={
            <SecureRoute>
              <Discounts />
            </SecureRoute>
          }
        />
        <Route
          path="/discount/create"
          element={
            <SecureRoute>
              <DiscountCreate />
            </SecureRoute>
          }
        />
        <Route
          path="/discount/:id"
          element={
            <SecureRoute>
              <DiscountDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/discount/:id/update"
          element={
            <SecureRoute>
              <DiscountUpdate />
            </SecureRoute>
          }
        />
        <Route
          path="/order/:id"
          element={
            <SecureRoute>
              <OrderDetails />
            </SecureRoute>
          }
        />
        <Route
          path="/app-setting"
          element={
            <SecureRoute>
              <AppSetting />
            </SecureRoute>
          }
        />
        <Route
          path="/admin-setting"
          element={
            <SecureRoute>
              <AdminSetting />
            </SecureRoute>
          }
        />
        <Route
          path="/queries"
          element={
            <SecureRoute>
              <Queries />
            </SecureRoute>
          }
        />
        <Route
          path="/query/:id"
          element={
            <SecureRoute>
              <QueryDetail />
            </SecureRoute>
          }
        />
        <Route
          path="/gifts"
          element={
            <SecureRoute>
              <Gifts />
            </SecureRoute>
          }
        />
        <Route
          path="/gift/create"
          element={
            <SecureRoute>
              <GiftCreate />
            </SecureRoute>
          }
        />
        <Route
          path="/gift/:id/update"
          element={
            <SecureRoute>
              <GiftUpdate />
            </SecureRoute>
          }
        />
          <Route
            path="/gift/use"
            element={
              <SecureRoute>
                <GiftUse />
              </SecureRoute>
            }
          />
          <Route
            path="/gift/:id/details"
            element={
              <SecureRoute>
                <GiftUseDetail />
              </SecureRoute>
            }
          />
        <Route
          path="/birthday"
          element={
            <SecureRoute>
              <Birthday />
            </SecureRoute>
          }
        />
        <Route
          path="/birthday/:id"
          element={
            <SecureRoute>
              <BirthdayDetail />
            </SecureRoute>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <SecureRoute>
              <Profile />
            </SecureRoute>
          }
        />
        <Route path="/404" element={<Error404 />} />
        <Route path="/verified/:email/:id" element={<Verified />} />
        <Route path="/terms&conditions" element={<Terms />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/support" element={<Support />} />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;

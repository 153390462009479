import React from "react";
import { Col, Row } from "react-bootstrap";

const Terms = () => {
  const darkModeStyles = {
    container: {
      backgroundColor: "#121212",
      color: "#ffffff",
      padding: "1rem",
      borderRadius: "5px",
    },
    link: {
      color: "#ffd700",
      textDecoration: "none",
    },
    header: {
      fontSize: "23px",
      textAlign: "center",
    },
    sectionHeader: {
      fontSize: "23px",
    },
    logo: {
      filter: "invert(100%)",
    },
  };

  return (
    <div className="container my-4">
      <div className="d-flex flex-column justify-content-center align-items-center auth px-0 w-100">
        <Row className="w-100 mx-0">
          <Col lg={12} md={12} sm={12} className="mx-auto">
            <div
              className="auth-form-light text-left py-5 px-4 px-sm-5"
              style={darkModeStyles.container}
            >
              <div className="brand-logo d-flex">
                <img
                  className="mx-auto"
                  src={require("../../../../assets/images/logo.png")}
                  alt="logo"
                  // style={darkModeStyles.logo}
                />
              </div>
              <p style={darkModeStyles.header}>
                <b>
                  <center>TERMS AND CONDITIONS</center>
                </b>
              </p>
              <p style={darkModeStyles.sectionHeader}>
                <b>1. Overview</b>
              </p>
              <p>
                1-1 Welcome to "SK8GYM", a mobile application developed by
                CenterVortex by Francesco Ferraro ("Owner"). The following terms
                and conditions ("Terms") govern your use of the "SK8GYM" mobile
                app ("App"). By using the App, you agree to be bound by these
                Terms. If you do not agree to these Terms, do not use the App.
              </p>

              <p style={darkModeStyles.sectionHeader}>
                <b>Use of App</b>
              </p>
              <li> Eligibility</li>
              <p>
                You must be at least 13 years old to use the App. If you are
                under 18 years old, you may only use the App with the consent
                and supervision of a parent or legal guardian
              </p>
              <p>
                <br />
                <li> License</li>
                <p>
                  The Owner grants you a limited, non-exclusive,
                  non-transferable, revocable license to use the App for your
                  personal, non-commercial use only.
                </p>
                <br />
                <li> Prohibited Activities</li>
                <p>
                  You agree not to use the App for any illegal or unauthorized
                  purpose, including but not limited to: Harassing or
                  threatening other users; Transmitting any content that is
                  obscene, defamatory, or otherwise objectionable; Impersonating
                  any person or entity; Interfering with the operation of the
                  App or the Owner's servers or networks; Attempting to gain
                  unauthorized access to the App or the Owner's servers or
                  networks.
                </p>
                <br />
                <li> User Content</li>
                <p>
                  The App may allow you to submit content, including but not
                  limited to photos, videos, and text ("User Content"). By
                  submitting User Content, you grant the Owner a non-exclusive,
                  transferable, sub-licensable, royalty-free, worldwide license
                  to use, copy, modify, create derivative works based on,
                  distribute, publicly display, publicly perform, and otherwise
                  exploit in any manner such User Content in all formats and
                  distribution channels now known or hereafter devised
                  (including in connection with the App and the Owner's business
                  and on third-party sites and services), without further notice
                  to or consent from you, and without the requirement of payment
                  to you or any other person or entity. You represent and
                  warrant that: (i) you either are the sole and exclusive owner
                  of all User Content or you have all rights, licenses,
                  consents, and releases that are necessary to grant to the
                  Owner the rights in such User Content, as contemplated under
                  these Terms; and (ii) neither the User Content, nor your
                  submission, uploading, publishing or otherwise making
                  available of such User Content, nor the Owner's use of the
                  User Content as permitted herein will infringe, misappropriate
                  or violate a third party's patent, copyright, trademark, trade
                  secret, moral rights or other proprietary or intellectual
                  property rights, or rights of publicity or privacy, or result
                  in the violation of any applicable law or regulation.
                </p>

                <br />
                <li>Payment</li>
                <p>
                  The App may offer certain features or services that require
                  payment. If you choose to use such features or services, you
                  agree to pay all applicable fees and taxes in a timely manner.
                  The Owner reserves the right to change the fees or payment
                  terms at any time without notice.
                </p>
                <br />
                <li>Privacy Policy</li>
                <p>
                  The Owner respects your privacy and is committed to protecting
                  your personal information. Please refer to our Privacy Policy
                  [link to Privacy Policy] for information about how we collect,
                  use, and disclose your personal information.
                </p>
                <br />
                <li>Disclaimers and Limitations of Liability</li>
                <ol>
                  <br />
                  <li>
                    {" "}
                    Disclaimers
                    <p>
                      THE APP IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS
                      WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED,
                      INCLUDING BUT NOT LIMITED TO WARRANTIES OF
                      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                      NON-INFRINGEMENT, OR IMPLIED WARRANTIES ARISING FROM
                      COURSE OF DEALING OR COURSE OF PERFORMANCE. THE OWNER DOES
                      NOT WARRANT THAT THE APP WILL BE UNINTERRUPTED OR
                      ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE
                      APP OR THE SERVERS THAT MAKE THE APP AVAILABLE ARE FREE OF
                      VIRUSES OR OTHER HARMFUL COMPONENTS.
                    </p>
                  </li>
                  <li>
                    {" "}
                    Limitations of Liability
                    <p>
                      IN NO EVENT SHALL THE OWNER BE LIABLE FOR ANY DIRECT,
                      INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
                      DAMAGES, INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF
                      PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES
                      (EVEN IF THE OWNER HAS BEEN ADVISED OF THE POSSIBILITY OF
                      SUCH DAMAGES), ARISING OUT OF OR IN CONNECTION WITH YOUR
                      USE OR INABILITY TO USE THE APP.
                    </p>
                  </li>
                </ol>
                <p>
                  <br />

                  <li>Indemnification</li>
                  <p>
                    You agree to indemnify and hold harmless the Owner, its
                    affiliates, officers, agents, and employees from any and all
                    claims, damages, liabilities, costs, and expenses (including
                    reasonable attorneys' fees) arising out of or in connection
                    with your use of the App or your violation of these Terms.
                  </p>
                  <br />

                  <li>Modifications to Terms</li>
                  <p>
                    The Owner reserves the right to modify these Terms at any
                    time. Any changes to these Terms will be effective
                    immediately upon posting on the App. Your continued use of
                    the App after any changes to these Terms constitutes your
                    acceptance of the modified Terms.
                  </p>
                  <br />

                  <li>Termination</li>
                  <p>
                    The Owner may terminate your access to the App at any time,
                    without cause or notice, which may result in the forfeiture
                    and destruction of all information associated with your
                    account. All provisions of these Terms that by their nature
                    should survive termination shall survive termination,
                    including, without limitation, ownership provisions,
                    warranty disclaimers, indemnity, and limitations of
                    liability.
                  </p>
                  <br />

                  <li>Governing Law and Jurisdiction</li>
                  <p>
                    These Terms shall be governed by and construed in accordance
                    with the laws of Italy, without giving effect to any
                    principles of conflicts of law. Any dispute arising out of
                    or in connection with these Terms shall be resolved
                    exclusively in the court of Varese, Italy.
                  </p>
                  <p>
                    {" "}
                    Contact Information <br />
                    <br />
                    If you have any questions about these Terms, please contact
                    us at{" "}
                    <a className="XqQF9c" style={darkModeStyles.link}>
                      <strong>SK8GYM</strong>
                    </a>
                  </p>
                </p>
              </p>
              <p></p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Terms;

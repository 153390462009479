import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminSetting,
  updateAdminSetting,
} from "../../../redux/slices/adminSetting";

const AdminSetting = () => {
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state.userSlice);
  const { adminSetting } = useSelector((state) => state.adminSettingSlice);

  console.log(adminSetting, "adminSetting");

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    primaryEmail: "",
    secondaryEmail: "",
    tertiaryEmail: "",
    serviceTax: 0,
    rescheduleBeforeTime: "",
    cancelBeforeTime: "",
  });

  useEffect(() => {
    setFormData({
      primaryEmail: admin?.email,
      secondaryEmail: admin.otherEmails?.secondaryEmail,
      tertiaryEmail: admin.otherEmails?.tertiaryEmail,
      serviceTax: adminSetting.serviseTax?.[0].serviceTax,
      rescheduleBeforeTime: adminSetting.weatherTime?.rescheduleBeforeTime,
      cancelBeforeTime: adminSetting.weatherTime?.cancelBeforeTime,
    });
  }, [admin, adminSetting]);

  useEffect(() => {
    dispatch(getAdminSetting(setLoading));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (formData) {
      console.log(formData);
      dispatch(updateAdminSetting(formData, setLoading));
    } else {
      setLoading(false);
    }
  };

  const renderHourOptions = () => {
    return Array.from({ length: 24 }, (_, i) => i + 1).map((hour) => (
      <option key={hour} value={hour}>
        {hour}
      </option>
    ));
  };

  return (
    <div>
      <div className="page-header">
        {/* <h3 className="page-title">App Setting</h3> */}
      </div>
      <Row className="d-flex justify-content-center">
        <Col md={4} className="">
          <div className="card">
            <div className="card-body">
              <h6 className="card-title d-flex align-items-center">
                <MdOutlineAdminPanelSettings />
                &nbsp;
                <b>Admin Setting</b>
              </h6>

              <Row>
                <Col md={12} className="profile_info">
                  <div class="mb-3 input_dark flex-column align-items-start">
                    <label for="exampleInputEmail1" class="form-label">
                      Primary Email
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      disabled
                      id="exampleInputEmail1"
                      placeholder="Primary Email"
                      aria-describedby="emailHelp"
                      value={formData?.primaryEmail}
                    />
                  </div>
                  <div class="mb-3 input_dark flex-column align-items-start">
                    <label for="exampleInputEmail1" class="form-label">
                      Secondary Email
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter Secondary Email"
                      aria-describedby="emailHelp"
                      name="secondaryEmail"
                      value={formData?.secondaryEmail}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          secondaryEmail: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div class="mb-3 input_dark flex-column align-items-start">
                    <label for="exampleInputEmail1" class="form-label">
                      Tertiary Email
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter Tertiary Email"
                      aria-describedby="emailHelp"
                      name="tertiaryEmail"
                      value={formData?.tertiaryEmail}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          tertiaryEmail: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div class="mb-3 input_dark flex-column align-items-start">
                    <label for="exampleInputEmail1" class="form-label">
                      Service Tax (in $)
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputEmail1"
                      placeholder="Enter Service Tax (in %)"
                      aria-describedby="emailHelp"
                      name="serviceTax"
                      value={formData?.serviceTax}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          serviceTax: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3 input_dark flex-column align-items-start">
                    <label
                      htmlFor="rescheduleBeforeTime"
                      className="form-label"
                    >
                      Reschedule Time (in Hours)
                    </label>
                    <select
                      className="form-control"
                      name="rescheduleBeforeTime"
                      value={formData?.rescheduleBeforeTime}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          rescheduleBeforeTime: e.target.value,
                        });
                      }}
                    >
                      {renderHourOptions()}
                    </select>
                  </div>
                  <div className="mb-3 input_dark flex-column align-items-start">
                    <label htmlFor="cancelBeforeTime" className="form-label">
                      Cancel Time (in Hours)
                    </label>
                    <select
                      className="form-control"
                      name="cancelBeforeTime"
                      value={formData?.cancelBeforeTime}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          cancelBeforeTime: e.target.value,
                        });
                      }}
                    >
                      {renderHourOptions()}
                    </select>
                  </div>
                </Col>
                <Col md={6} className="">
                  <button
                    disabled={loading}
                    className="btn_primary_outline_sm py-2 mt-4 w-100"
                    onClick={handleSubmit}
                  >
                    {loading ? <Spinner></Spinner> : "Update"}
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AdminSetting;

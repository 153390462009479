import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const appsettingSlice = createSlice({
  name: "appsetting",
  initialState: {
    loading: false,
    setting: {},
  },
  reducers: {
    getSettingSuccess: (state, action) => {
        state.setting = action.payload
    }
  },
});
const { getSettingSuccess } = appsettingSlice.actions;

export const getSetting = (setLoading) => async (dispatch) => {
    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/setting/get/app/version/v1`;
        const res = await axios.get(url);
        dispatch(getSettingSuccess(res.data.data))
        setLoading(false);
    } catch (error) {
        console.log(error);
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
        });
        setLoading(false);
    }
}

export const updateSetting = (payload, setLoading) => async (dispatch) => {
    try {
        const url = `${process.env.REACT_APP_API_BASE_URL}/setting/admin/update/app/version/v1`;
        const res = await axios.put(url, payload, {headers: {Authorization: localStorage.getItem("sgtoken")}});
        setLoading(false);
    } catch (error) {
        console.log(error);
        toast.error(error.response.data.message, {
          position: "top-center",
          autoClose: 5000,
        });
        setLoading(false);
    }
}

export default appsettingSlice.reducer
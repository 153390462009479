import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const querySlice = createSlice({
  name: "query",
  initialState: {
    query: {},
    queryList: [],
    refetch: false,
  },
  reducers: {
    getAllQuerySuccess: (state, action) => {
      state.queryList = action.payload;
    },
    getQueryDetailSuccess: (state, action) => {
      state.query = action.payload;
    },
  },
});

export const { getAllQuerySuccess, getQueryDetailSuccess } = querySlice.actions;

export const getAllQuery = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/contactUs/list/v1`;
    const res = await axios.get(url, {
      headers: { Authorization: localStorage.getItem("sgtoken") },
    });
    dispatch(getAllQuerySuccess(res.data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};
export const getQueryDetail = (id, setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/contactUs/details/${id}/v1`;
    const res = await axios.get(url, {
      headers: { Authorization: localStorage.getItem("sgtoken") },
    });
    dispatch(getQueryDetailSuccess(res.data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export default querySlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const rentalSlice = createSlice({
  name: "rental",
  initialState: {
    rentals: [],
    refetch: false,
    rentalDetails: {},
    dimensionDetails: {},
  },
  reducers: {
    getAllRentalsSuccess: (state, action) => {
      state.rentals = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getRentalDetailsSuccess: (state, action) => {
      state.rentalDetails = action.payload;
    },
    getDimensioDetailsSuccess: (state, action) => {
      state.dimensionDetails = action.payload;
    },
  },
});

export const {
  getAllRentalsSuccess,
  refetch,
  getRentalDetailsSuccess,
  getDimensioDetailsSuccess,
} = rentalSlice.actions;

export const getAllRentals = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/rentals/get/admin/rentals/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAllRentalsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getRentalDetails = (id, setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/rentals/admin/get/rentals/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getRentalDetailsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getDimensionDetails =
  (id, dimensionId, setIsLoading) => async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/rentals/get/dimensions/details/${id}/${dimensionId}/v1`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });
      dispatch(getDimensioDetailsSuccess(data.data));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

export const createNewRental =
  (rentalForm, selectedFile, navigate, setIsLoading) => async (dispatch) => {
    try {
      if (selectedFile.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/rentals/upload/rental/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        rentalForm.rentalImage = imgArr;
      }

      const urlPre = `${process.env.REACT_APP_API_BASE_URL}/rentals/create/v1`;
      const responsePre = await axios.post(urlPre, rentalForm, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });

      console.log(responsePre.data);
      toast.success("Rental created successfully", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Error creating rental", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

export const updateRental =
  (rentalId, rentalForm, selectedFile, navigate, setIsLoading) =>
  async (dispatch) => {
    try {
      if (selectedFile.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/rentals/upload/rental/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        rentalForm.rentalImage = imgArr;
      }

      const urlPre = `${process.env.REACT_APP_API_BASE_URL}/rentals/update/${rentalId}/v1`;
      const responsePre = await axios.put(urlPre, rentalForm, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });

      console.log(responsePre.data);
      toast.success("Rental created successfully", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

export const deleteRental =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/rentals/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      console.log(data);
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export const deleteRentalDimension =
  (id, rentalId, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/rentals/delete/${rentalId}/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      console.log(data);
      setDeleteModal(false);
      setIsLoading(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export const addDimensionInRental =
  (id, dimensionForm, setDimensionForm, setIsLoading, setModalShow) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/rentals/add/dimensions/${id}/v1`;
      const { data } = await axios.put(
        url,
        { dimensions: dimensionForm },
        {
          headers: { Authorization: localStorage.getItem("sgtoken") },
        }
      );
      console.log(data);
      setIsLoading(false);
      dispatch(refetch(true));
      setModalShow(false);
      setDimensionForm([
        {
          width: null,
          size: null,
          color: null,
          age: null,
          price: "",
          availableStocks: "",
        },
      ]);
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setModalShow(false);
      setDimensionForm([
        {
          width: null,
          size: null,
          color: null,
          age: null,
          price: "",
          availableStocks: "",
        },
      ]);
    }
  };

export const updateDimensionInRental =
  (rentalId, id, dimensionForm, setDimensionForm, setIsLoading, setModalShow,setDimensionId) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/rentals/update/dimension/${rentalId}/${id}/v1`;
      const { data } = await axios.put(
        url,
        { dimensions: dimensionForm },
        {
          headers: { Authorization: localStorage.getItem("sgtoken") },
        }
      );
      console.log(data);
      setIsLoading(false);
      dispatch(refetch(true));
      setModalShow(false);
      setDimensionId("")
      setDimensionForm([
        {
          width: null,
          size: null,
          color: null,
          age: null,
          price: "",
          availableStocks: "",
        },
      ]);
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setModalShow(false);
      setDimensionForm([
        {
          width: null,
          size: null,
          color: null,
          age: null,
          price: "",
          availableStocks: "",
        },
      ]);
    }
  };

export default rentalSlice.reducer;

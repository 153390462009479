import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const discountPackageSlice = createSlice({
  name: "discountPackage",
  initialState: {
    discountPackages: [],
    refetch: false,
    discountPackageDetails: {},
  },
  reducers: {
    getAllDiscountPackageSuccess: (state, action) => {
      state.discountPackages = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getDiscountPackagesSuccess: (state, action) => {
      state.discountPackageDetails = action.payload;
    },
  },
});

export const {
  getAllDiscountPackageSuccess,
  refetch,
  getDiscountPackagesSuccess,
} = discountPackageSlice.actions;

export const getAllDiscountPackages = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/discount/package/admin/get/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAllDiscountPackageSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getdiscountPackageDetails =
  (id, setIsLoading) => async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/discount/package/admin/get/details/${id}/v1`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });
      dispatch(getDiscountPackagesSuccess(data.data));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

export const deleteDiscountPackage =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/discount/package/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export const createDiscountPackage =
  (discountForm, selectedFile, navigate, setIsLoading) => async (dispatch) => {
    try {
      if (selectedFile.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/discount/package/upload/discount/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        discountForm.image = imgArr[0];
      }

      const urlPre = `${process.env.REACT_APP_API_BASE_URL}/discount/package/create/v1`;
      const responsePre = await axios.post(urlPre, discountForm, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });

      toast.success("Discount Pacakge Created Successfully", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Error creating Discount Package", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

export const updateDiscountPackage =
  (id, discountForm, selectedFile, navigate, setIsLoading) =>
  async (dispatch) => {
    try {
      if (selectedFile.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/discount/package/upload/discount/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        discountForm.image = imgArr[0];
      }

      const urlPre = `${process.env.REACT_APP_API_BASE_URL}/discount/package/update/${id}/v1`;
      const responsePre = await axios.put(urlPre, discountForm, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });

      toast.success("Updated Successfully", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Error Updating Discount Package", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

export default discountPackageSlice.reducer;

import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Modal, ModalBody } from "react-bootstrap";
import { RxAvatar } from "react-icons/rx";
import { MdOutlineLogout } from "react-icons/md";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/slices/authSlice";

const Navbar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = React.useState(false);
  const { admin } = useSelector((state) => state.userSlice);
  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };
  const handleLogout = () => {
    dispatch(logout(navigate));
  };

  const targetRef = useRef(null);
  const [profiledropdown, setProfiledropdown] = React.useState(false);
  const handleButtonClick = (event) => {
    event.stopPropagation(); // Stop event propagation
    setProfiledropdown(!profiledropdown);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (targetRef.current && !targetRef.current.contains(event.target)) {
        setProfiledropdown(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  return (
    <>
      <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/dashboard">
            <img src={require("../../../assets/images/logo.png")} alt="logo" />
          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/dashboard">
            <img src={require("../../../assets/images/logo.png")} alt="logo" />
          </Link>
        </div>
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button
            className="navbar-toggler navbar-toggler align-self-center"
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <FeatherIcon className="cursor-pointer" icon="menu" size="18" />
          </button>

          {/* <CustomModal
            confirmOnClick={handleLogout}
            btnClass="mdi-logout mx-2 ms-auto text-primary ml-auto align-self-center navbar-logout-btn"
            modalBodyContent="Are you sure want to Logout!"
          /> */}
          <div className="navbar_profile my-auto ms-auto">
            <span onClick={handleButtonClick} className="navbar_profile_btn">
              <RxAvatar size={25} />{" "}
              {admin.role == "subAdmin"
                ? `${admin.firstName}${" "}${admin.lastName}`
                : "Admin"}
            </span>
            {profiledropdown ? (
              <div className="navbar_profile_dropdown" ref={targetRef}>
                <button onClick={() => navigate("/profile")}>
                  Profile <RxAvatar size={18} />
                </button>
                <button onClick={() => setModalShow(true)}>
                  Logout <MdOutlineLogout size={18} />
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
            <FeatherIcon className="cursor-pointer" icon="menu" size="18" />
          </button>
        </div>
      </nav>
      <Modal
        show={modalShow}
        size="sx"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalBody>
          <h4>Are you sure want to logout!</h4>
        </ModalBody>
        <Modal.Footer>
          <button
            type="button"
            className="btn_secondary"
            onClick={() => setModalShow(false)}
          >
            Not now
          </button>
          <button onClick={handleLogout} type="button" className="btn_primary">
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Navbar;

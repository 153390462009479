import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const trainerSlice = createSlice({
  name: "rental",
  initialState: {
    trainers: [],
    refetch: false,
    trainerDetails: {},
  },
  reducers: {
    getAllTrainerSuccess: (state, action) => {
      state.trainers = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getTrainerDetailsSuccess: (state, action) => {
      state.trainerDetails = action.payload[0];
    },
  },
});

export const { getAllTrainerSuccess, refetch, getTrainerDetailsSuccess } =
  trainerSlice.actions;

export const getAllTrainers = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/trainer/get/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAllTrainerSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getTrainerDetails = (id, setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/trainer/admin/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getTrainerDetailsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const createNewTrainer =
  (
    trainerForm,
    selectedCountry,
    selectedFile1,
    selectedFile,
    selectedVid,
    selectedThumbnail,
    navigate,
    setIsLoading
  ) =>
  async (dispatch) => {
    try {
      trainerForm.countryCode = selectedCountry;

      if (selectedFile1.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile1.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/trainer/upload/trainer/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        await Promise.all(uploadPromises);
        trainerForm.trainerImage = imgArr[0];
      }

      if (selectedFile.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/trainer/upload/trainer/training/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        await Promise.all(uploadPromises);
        trainerForm.trainingImage = imgArr;
      }

      if (selectedVid.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedVid.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/trainer/upload/trainer/training/video/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });

            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        await Promise.all(uploadPromises);
        trainerForm.trainerVideos = imgArr;
      }

      if (selectedThumbnail.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedThumbnail.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/trainer/upload/trainer/training/video/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });

            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        await Promise.all(uploadPromises);
        trainerForm.trainerVideoThumbnail = imgArr;
      }

      const urlPre = `${process.env.REACT_APP_API_BASE_URL}/trainer/create/v1`;
      const responsePre = await axios.post(urlPre, trainerForm, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });

      toast.success("Trainer Addded successfully", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Error creating rental", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

export const updateTrainer =
  (
    id,
    trainerForm,
    selectedCountry,
    selectedFile1,
    selectedFile,
    selectedVid,
    selectedThumbnail,
    navigate,
    setIsLoading
  ) =>
  async (dispatch) => {
    try {
      trainerForm.countryCode = selectedCountry;
      if (selectedFile1.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile1.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/trainer/upload/trainer/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        await Promise.all(uploadPromises);
        trainerForm.trainerImage = imgArr[0];
      }

      if (selectedFile.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/trainer/upload/trainer/training/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        await Promise.all(uploadPromises);
        trainerForm.trainingImage = [...trainerForm.trainingImage, ...imgArr];
      }

      if (selectedVid.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedVid.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/trainer/upload/trainer/training/video/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        await Promise.all(uploadPromises);
        console.log(trainerForm, "tr");
        console.log(imgArr, "imgArr");
        trainerForm.trainerVideos = [...trainerForm.trainerVideos, ...imgArr];
      }

      if (selectedThumbnail.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedThumbnail.map(async (file) => {
          if (typeof file === "string") {
            imgArr.push(file);
            return file;
          } else {
            try {
              const urlPre = `${process.env.REACT_APP_API_BASE_URL}/trainer/upload/trainer/training/video/v1`;
              const imgRes = await axios.post(
                urlPre,
                {
                  sFileName: file.name,
                  sContentType: file.type,
                },
                {
                  headers: {
                    Authorization: localStorage.getItem("sgtoken"),
                  },
                }
              );
              const url = imgRes.data.data.sUrl;
              await axios.put(url, file, {
                headers: { "Content-Type": file.type },
              });

              imgArr.push(`${imgRes.data.data.sPath}`);
              return imgRes.data.data.sPath;
            } catch (error) {
              console.error("Error uploading file:", error);
              throw error;
            }
          }
        });

        await Promise.all(uploadPromises);
        trainerForm.trainerVideoThumbnail = imgArr;
      }

      const urlPre = `${process.env.REACT_APP_API_BASE_URL}/trainer/update/${id}/v1`;
      const responsePre = await axios.put(urlPre, trainerForm, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });

      toast.success("Trainer Updated successfully", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Error creating rental", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

export const deleteTrainer =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/trainer/make/inactive/trainer/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      console.log(data);
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export const blockTrainer =
  (id, blockInterval, setIsLoading) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/trainer/block/trainer/${id}/v1`;
      const { data } = await axios.put(url, blockInterval, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });
      dispatch(refetch(true));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

export const unblockTrainer = (id, setIsLoading) => async (dispatch) => {
  try {
    dispatch(refetch(false));
    const url = `${process.env.REACT_APP_API_BASE_URL}/trainer/unblock/trainer/${id}/v1`;
    const { data } = await axios.put(
      url,
      {},
      {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      }
    );
    dispatch(refetch(true));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export default trainerSlice.reducer;

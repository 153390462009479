import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import userSlice from "./slices/userSlice";
import categorySlice from "./slices/categorySlice";
import locationSlice from "./slices/locationSlice";
import rentalSlice from "./slices/rentalSlice";
import appsettingSlice from "./slices/appsettingSlice";
import orderSlice from "./slices/orderSlice";
import trainerSlice from "./slices/trainerSlice";
import serviceSlice from "./slices/serviceSlice";
import discountPackageSlice from "./slices/discountPackageSlice";
import subAdminSlice from "./slices/subAdminSlice";
import giftSlice from "./slices/giftSlice";
import birthdaySlice from "./slices/birthdaySlice";
import querySlice from "./slices/querySlice";
import discountBookingSlice from "./slices/discountBookingSlice";
import adminSettingSlice from "./slices/adminSetting";

export default configureStore({
  reducer: {
    authSlice,
    userSlice,
    categorySlice,
    locationSlice,
    rentalSlice,
    appsettingSlice,
    orderSlice,
    discountBookingSlice,
    trainerSlice,
    serviceSlice,
    discountPackageSlice,
    subAdminSlice,
    giftSlice,
    birthdaySlice,
    querySlice,
    adminSettingSlice,
  },
});

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const subAdminSlice = createSlice({
  name: "rental",
  initialState: {
    subAdmins: [],
    refetch: false,
    subAdminDetails: {},
  },
  reducers: {
    getAllSubAdminSuccess: (state, action) => {
      state.subAdmins = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getSubAdminDetailsSuccess: (state, action) => {
      state.subAdminDetails = action.payload;
    },
  },
});

export const { getAllSubAdminSuccess, refetch, getSubAdminDetailsSuccess } =
  subAdminSlice.actions;

export const getAllSubAdminList = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/subAdmin/get/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAllSubAdminSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getSubAdminDetails =
  (id, setIsLoading) => async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/subAdmin/get/details/${id}/v1`;
      const { data } = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });
      dispatch(getSubAdminDetailsSuccess(data.data));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };


export const deleteSubAdmin =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/subAdmin/remove/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      console.log(data);
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

  export const createSubAdmin =
  (formData, setIsLoading, navigate) =>
  async (dispatch) => {
    try {

      const url = `${process.env.REACT_APP_API_BASE_URL}/subAdmin/create/v1`;
      const { data } = await axios.post(url, formData, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      setIsLoading(false);
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      navigate(-1)
    } catch (error) {
      setIsLoading(false);
      
    }
  };

  export const updateSubAdmin =
  (id,formData, setIsLoading, navigate) =>
  async (dispatch) => {
    try {

      const url = `${process.env.REACT_APP_API_BASE_URL}/subAdmin/update/${id}/v1`;
      const { data } = await axios.put(url, formData, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      setIsLoading(false);
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      navigate(-1)
    } catch (error) {
      setIsLoading(false);
    }
  };


export default subAdminSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const adminSettingSlice = createSlice({
  name: "adminSetting",
  initialState: {
    refetch: false,
    adminSetting: {},
  },
  reducers: {
    // getAllBirthdaySuccess: (state, action) => {
    //   state.birthdayList = action.payload;
    // },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getServiceTaxSuccess: (state, action) => {
      state.adminSetting = action.payload;
    },
  },
});

export const { getServiceTaxSuccess } = adminSettingSlice.actions;

export const getAdminSetting = (setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/user/admin/get/service/tax/v1`;
    const res = await axios.get(url, {
      headers: { Authorization: localStorage.getItem("sgtoken") },
    });
    dispatch(getServiceTaxSuccess(res.data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    toast.error(error.response.data.message, {
      position: "top-center",
      autoClose: 5000,
    });
    setLoading(false);
  }
};

export const updateAdminSetting =
  (formData, setLoading) => async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/user/add/email/or/tax/v1`;
      const { data } = await axios.put(url, formData, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

export default adminSettingSlice.reducer;

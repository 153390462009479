import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const discountBookingSlice = createSlice({
  name: "discountbooking",
  initialState: {
    discountBookingList: [],
    discountBooking: {},
    refetch: false,
  },
  reducers: {
    getAllDiscountBookingSuccess: (state, action) => {
      state.discountBookingList = action.payload;
    },
    getDiscountBookingDetailSuccess: (state, action) => {
      state.discountBooking = action.payload;
    },
  },
});

export const { getAllDiscountBookingSuccess, getDiscountBookingDetailSuccess } =
  discountBookingSlice.actions;

export const getAllDiscountBooking = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/discount/booking/admin/get/list/v1`;
    const { data } = await axios.get(url, {
      headers: { Authorization: localStorage.getItem("sgtoken") },
    });
    // console.log(data.data);
    dispatch(getAllDiscountBookingSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};
export const getDiscountBookingDetail =
  (id, setIsLoading) => async (dispatch) => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/discount/booking/admin/get/details/${id}/v1`;
      const {data} = await axios.get(url, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      console.log(data.data);
      dispatch(getDiscountBookingDetailSuccess(data.data));
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

export default discountBookingSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const serviceSlice = createSlice({
  name: "service",
  initialState: {
    services: [],
    refetch: false,
    serviceDetails: {},
  },
  reducers: {
    getAllServicesSuccess: (state, action) => {
      state.services = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getServiceDetailsSuccess: (state, action) => {
      state.serviceDetails = action.payload;
    },
  },
});

export const { getAllServicesSuccess, refetch, getServiceDetailsSuccess } =
  serviceSlice.actions;

export const getAllServices = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/services/admin/get/services/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAllServicesSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getServicedetails = (id, setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/services/admin/get/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getServiceDetailsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const deleteService =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/services/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export const createService =
  (serviceForm, selectedFile, navigate, setIsLoading) => async (dispatch) => {
    try {
      if (selectedFile.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/services/upload/service/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        serviceForm.serviceImage = imgArr[0];
      }

      const urlPre = `${process.env.REACT_APP_API_BASE_URL}/services/create/v1`;
      const responsePre = await axios.post(urlPre, serviceForm, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });

      toast.success("Service created successfully", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Error creating Service", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

export const updateService =
  (id, serviceForm, selectedFile, navigate, setIsLoading) =>
  async (dispatch) => {
    try {
      if (selectedFile.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/services/upload/service/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        serviceForm.serviceImage = imgArr[0];
      }

      const urlPre = `${process.env.REACT_APP_API_BASE_URL}/services/update/${id}/v1`;
      const responsePre = await axios.put(urlPre, serviceForm, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });

      toast.success("Service Updated successfully", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Error updating service", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

export default serviceSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const locationSlice = createSlice({
  name: "location",
  initialState: {
    locations: [],
    refetch: false,
    locationDetails: {},
  },
  reducers: {
    getAllLocationSuccess: (state, action) => {
      state.locations = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getLocationDetailsSuccess: (state, action) => {
      state.locationDetails = action.payload;
    },
  },
});

export const { getAllLocationSuccess, refetch, getLocationDetailsSuccess } =
  locationSlice.actions;

export const getAllLocations = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/location/admin/get/all/locations/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAllLocationSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getLocationDetails = (id, setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/location/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getLocationDetailsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const deleteLocation =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/location/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export const updateLocation =
  (
    id,
    locationForm,
    setIsLoading,
    setLocationForm,
    setOpenMap,
    setModalShow,
    setLocationId
  ) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/location/update/${id}/v1`;
      const { data } = await axios.put(url, locationForm, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      setOpenMap(false);
      setIsLoading(false);
      setModalShow(false);
      setLocationForm({
        locationName: "",
        categories: [],
        location: {},
      });
      setLocationId("");
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      setOpenMap(false);
      setIsLoading(false);
      setModalShow(false);
      setLocationForm({
        locationName: "",
        categories: [],
        location: {},
      });
      setLocationId("");
    }
  };

export const addLocation =
  (locationForm, setIsLoading, setLocationForm, setOpenMap, setModalShow) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/location/create/v1`;
      const { data } = await axios.post(url, locationForm, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      console.log(data);
      setOpenMap(false);
      setIsLoading(false);
      setModalShow(false);
      setLocationForm({
        locationName: "",
        categories: [],
        location: {},
      });
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      setOpenMap(false);
      setIsLoading(false);
      setModalShow(false);
      setLocationForm({
        locationName: "",
        categories: [],
        location: {},
      });
    }
  };

export const addAreaName =
  (
    id,
    areas,
    setShowAreaModal,
    setAreas,
    setLocationId,
    setAreaName,
    setIsLoading
  ) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/location/add/area/name/${id}/v1`;
      const { data } = await axios.put(url, areas, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });
      dispatch(refetch(true));
      setShowAreaModal(false);
      setIsLoading(false);
      setAreas([]);
      setLocationId("");
      setAreaName("");
    } catch (error) {
      console.log(error);
      setShowAreaModal(false);
      setIsLoading(false);
      setAreas([]);
      setLocationId("");
      setAreaName("");
    }
  };
export default locationSlice.reducer;

import React from "react";
import { Col, Row } from "react-bootstrap";

const Support = () => {
  const darkModeStyles = {
    container: {
      backgroundColor: "#121212",
      color: "#ffffff",
      padding: "1rem",
      borderRadius: "5px",
    },
    link: {
      color: "#ffd700",
      textDecoration: "none",
    },
    header: {
      fontSize: "23px",
      textAlign: "center",
    },
    logo: {
      filter: "invert(100%)",
    },
  };

  return (
    <div className="container my-4">
      <div className="d-flex flex-column justify-content-start align-items-center auth px-0 w-100">
        <Row className="w-100 mx-0">
          <Col lg={12} md={12} sm={12} className="mx-auto">
            <div
              className="auth-form-light text-left py-5 px-4 px-sm-5"
              style={darkModeStyles.container}
            >
              <div className="brand-logo d-flex">
                <img
                  className="mx-auto"
                  src={require("../../../../assets/images/logo.png")}
                  alt="logo"
                  // style={darkModeStyles.logo}
                />
              </div>
              <p style={darkModeStyles.header}>
                <b>Support</b>
              </p>
              <p>
                For any kind of support related to any queries, feel free to
                reach out to us at:{" "}
              </p>
              <p dir="ltr" className="CDt4Ke zfr3Q">
                <span className="aw5Odc">
                  <a className="XqQF9c" style={darkModeStyles.link}>
                    <strong>{" "}SK8GYM{" "}</strong>
                  </a>
                </span>
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Support;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavLink from "../components/NavLink";
import { LuLayoutDashboard } from "react-icons/lu";
import { IoLocationOutline, IoSettingsOutline } from "react-icons/io5";
import { TbSkateboarding, TbUsers } from "react-icons/tb";
import { RiShieldUserLine } from "react-icons/ri";
import { RiPercentLine } from "react-icons/ri";
import { BiPurchaseTag } from "react-icons/bi";
import { IoBody } from "react-icons/io5";
import { CiBoxList } from "react-icons/ci";
import { MdOutlineHomeRepairService, MdOutlineAdminPanelSettings } from "react-icons/md";
import { useSelector } from "react-redux";
import { IoIosArrowDown, IoMdArrowDropright } from "react-icons/io";
import { TbMessage2Question } from "react-icons/tb";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import { FiGift } from "react-icons/fi";

const Sidebar = () => {
  const location = useLocation();
  const [menuState, setMenuState] = useState({});
  const [bodyClass, setBodyClass] = useState("sidebar-icon-only");
  const { admin } = useSelector((state) => state.userSlice);

  const sidebarData = [
    {
      commonUrl: "/",
      primary: {
        iconClass: <LuLayoutDashboard size={18} />,
        title: "Dashboard",
        url: "/",
      },
    },
    ...(admin?.role === "admin"
      ? [
          {
            commonUrl: "/sub-admin",
            primary: {
              iconClass: <RiShieldUserLine size={18} />,
              title: "Sub Admin",
              url: "/sub-admin",
            },
          },
        ]
      : []),
    {
      commonUrl: "/users",
      primary: {
        iconClass: <TbUsers size={18} />,
        title: "Users",
        url: "/users",
      },
    },
    {
      commonUrl: "/categories",
      primary: {
        iconClass: <TbSkateboarding strokeWidth={2} size={18} />,
        title: "Category",
        url: "/categories",
      },
    },
    {
      commonUrl: "/location",
      primary: {
        iconClass: <IoLocationOutline strokeWidth={5} size={18} />,
        title: "Location",
        url: "/location",
      },
    },
    {
      commonUrl: "/rentals",
      primary: {
        iconClass: <BiPurchaseTag strokeWidth={0} size={18} />,
        title: "Rentals",
        url: "/rentals",
      },
    },
    {
      commonUrl: "/trainer",
      primary: {
        iconClass: <IoBody strokeWidth={0} size={18} />,
        title: "Trainer",
        url: "/trainer",
      },
    },
    {
      commonUrl: "/services",
      primary: {
        iconClass: <MdOutlineHomeRepairService strokeWidth={0} size={18} />,
        title: "Services",
        url: "/services",
      },
    },
    {
      commonUrl: "/discounts",
      primary: {
        iconClass: <RiPercentLine strokeWidth={0} size={18} />,
        title: "Discount Packages",
        url: "/discounts",
      },
    },
    {
      commonUrl: "/bookings",
      primary: {
        iconClass: <CiBoxList strokeWidth={0} size={18} />,
        title: "Bookings",
        // url: "/bookings",
      },
      secondary: [
        {
          iconClass: <IoMdArrowDropright className="ms-0 me-2" />,
          title: "Program Booking",
          url: "/bookings/program",
        },
        {
          iconClass: <IoMdArrowDropright className="ms-0 me-2" />,
          title: "Discount Booking",
          url: "/bookings/discount",
        },
      ],
    },
    {
      commonUrl: "/gifts",
      primary: {
        iconClass: <FiGift strokeWidth={2} size={18} />,
        title: "Gift",
        // url: "/gifts",
      },
      secondary: [
        {
          iconClass: <IoMdArrowDropright className="ms-0 me-2" />,
          title: "Create",
          url: "/gifts",
        },
        {
          iconClass: <IoMdArrowDropright className="ms-0 me-2" />,
          title: "Use",
          url: "/gift/use",
        },
      ],
    },
    {
      commonUrl: "/birthday",
      primary: {
        iconClass: <LiaBirthdayCakeSolid strokeWidth={0.5} size={18} />,
        title: "Birthday",
        url: "/birthday",
      },
    },
    {
      commonUrl: "/queries",
      primary: {
        iconClass: <TbMessage2Question strokeWidth={2} size={18} />,
        title: "Query",
        url: "/queries",
      },
    },
    {
      commonUrl: "/app-setting",
      primary: {
        iconClass: <IoSettingsOutline strokeWidth={2} size={18} />,
        title: "App Setting",
        url: "/app-setting",
      },
    },
    {
      commonUrl: "/admin-setting",
      primary: {
        iconClass: <MdOutlineAdminPanelSettings  strokeWidth={0} size={18} />,
        title: "Admin Setting",
        url: "/admin-setting",
      },
    },
  ];

  const toggleMenuState = (menuStateKey) => {
    setMenuState((prevState) => ({
      ...Object.keys(prevState).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {}),
      [menuStateKey]: !prevState[menuStateKey],
    }));
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    setMenuState({});
  };

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });

    return () => {
      // Clean up event listeners if needed
    };
  }, [location]);

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        {sidebarData.map((data) => {
          return (
            <NavLink
              isPathActive={isPathActive}
              url={data.primary.url}
              iconClass={data.primary.iconClass}
              title={data.primary.title}
              commonUrl={data.commonUrl}
              child={data.secondary}
            />
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;

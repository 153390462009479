import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const categorySlice = createSlice({
  name: "category",
  initialState: {
    categories: [],
    refetch: false,
    categoryDetails: {},
  },
  reducers: {
    getAllCategorySuccess: (state, action) => {
      state.categories = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getCategoryDetailsSuccess: (state, action) => {
      state.categoryDetails = action.payload;
    },
  },
});

export const { getAllCategorySuccess, refetch, getCategoryDetailsSuccess } =
  categorySlice.actions;

export const getAllCategory = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/category/admin/get/all/categories/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAllCategorySuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const createNewCategory =
  (formData, selectedFile, setLoading, setModalShow, setFormData) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const file = selectedFile;
      if (file.length > 0) {
        const urlPre = `${process.env.REACT_APP_API_BASE_URL}/category/upload/category/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          {
            sFileName: file[0].name,
            sContentType: file[0].type,
          },
          {
            headers: {
              Authorization: localStorage.getItem("sgtoken"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        formData.categoryImage = sPath;

        axios.put(sUrl, file[0], {
          headers: {
            "Content-Type": file[0].sContentType,
          },
        });
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/category/create/v1`;
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });
      setLoading(false);
      setModalShow(false);
      setFormData({
        serviceName: "",
        requiredThingsForCategory: "",
      });
      dispatch(refetch(true));
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
    }
  };

export const getCategoryDetails = (id, setLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/category/get/category/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });

    dispatch(getCategoryDetailsSuccess(data.data));
    setLoading(false);
  } catch (error) {
    console.log(error);
    setLoading(false);
  }
};

export const deleteCategory =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/category/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      console.log(data);
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

export const updateCategory =
  (
    id,
    formData,
    selectedFile,
    setLoading,
    setUpdateModalShow,
    setFormData,
    setServiceId
  ) =>
  async (dispatch) => {
    try {
      dispatch(refetch(false));
      const file = selectedFile;
      if (file.length > 0) {
        const urlPre = `${process.env.REACT_APP_API_BASE_URL}/category/upload/category/image/v1`;
        const responsePre = await axios.post(
          urlPre,
          {
            sFileName: file[0].name,
            sContentType: file[0].type,
          },
          {
            headers: {
              Authorization: localStorage.getItem("sgtoken"),
            },
          }
        );

        const { sUrl, sPath } = responsePre.data.data;
        formData.categoryImage = sPath;

        axios.put(sUrl, file[0], {
          headers: {
            "Content-Type": file[0].sContentType,
          },
        });
      }
      const url = `${process.env.REACT_APP_API_BASE_URL}/category/update/${id}/v1`;
      const response = await axios.put(url, formData, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });
      setLoading(false);
      setUpdateModalShow(false);
      setFormData({
        serviceName: "",
        requiredThingsForCategory: "",
      });
      setServiceId("");
      dispatch(refetch(true));
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
    }
  };

export default categorySlice.reducer;

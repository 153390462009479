import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const orderSlice = createSlice({
  name: "rental",
  initialState: {
    orders: [],
    refetch: false,
    orderDetails: {},
    userOrderList: [],
  },
  reducers: {
    getAllOrderSuccess: (state, action) => {
      state.orders = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getOrderDetailsSuccess: (state, action) => {
      state.orderDetails = action.payload;
    },
    getUserAllOrderSuccess: (state, action) => {
      state.userOrderList = action.payload;
    },
  },
});

export const {
  getAllOrderSuccess,
  refetch,
  getOrderDetailsSuccess,
  getUserAllOrderSuccess,
} = orderSlice.actions;

export const getAllOrders = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/order/admin/get/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAllOrderSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getUserAllOrders = (id, setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/order/admin/get/user/${id}/order/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getUserAllOrderSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getOrderDetails = (id, setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/order/admin/get/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getOrderDetailsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const cancelOrder =
  (id, setIsLoading, setShowAlertModel) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/order/admin/cancel/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: { Authorization: localStorage.getItem("sgtoken") },
        }
      );

      setIsLoading(false);
      setShowAlertModel(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setShowAlertModel(false);
    }
  };

export const acceptOrder =
  (id, setIsLoading, setShowAlertModel) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/order/admin/accept/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: { Authorization: localStorage.getItem("sgtoken") },
        }
      );

      setIsLoading(false);
      setShowAlertModel(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setShowAlertModel(false);
    }
  };

export const rejectOrder =
  (id, setIsLoading, setShowAlertModel) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/order/admin/reject/${id}/v1`;
      const { data } = await axios.put(
        url,
        {},
        {
          headers: { Authorization: localStorage.getItem("sgtoken") },
        }
      );

      setIsLoading(false);
      setShowAlertModel(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setShowAlertModel(false);
    }
  };

export const approveStudent = (email,id) => async (dispatch) => {
  try {
    dispatch(refetch(false));
    const url = `${process.env.REACT_APP_API_BASE_URL}/intakeForm/confirm/verification/${email}/${id}/v1`;
    const { data } = await axios.put(
      url,
      {},
      {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      }
    );
  } catch (error) {
    console.log(error)
    // toast.error(error.response.data.message, {
    //   position: "top-center",
    //   autoClose: 5000,
    // });
  }
};

export default orderSlice.reducer;

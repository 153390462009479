import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

const giftSlice = createSlice({
  name: "category",
  initialState: {
    gifts: [],
    coupons:[],
    refetch: false,
    giftCertificateDetails: {},
    couponsDetails:{},
  },
  reducers: {
    getAllGiftsSuccess: (state, action) => {
      state.gifts = action.payload;
    },
    getAllCouponSuccess: (state, action) => {
      state.coupons = action.payload;
    },
    refetch: (state, action) => {
      state.refetch = action.payload;
    },
    getGiftDetailsSuccess: (state, action) => {
      state.giftCertificateDetails = action.payload;
    },
    getGiftCouponDetailsSuccess: (state, action) => {
      state.couponsDetails = action.payload;
    },
  },
});

export const { getAllGiftsSuccess,getAllCouponSuccess, refetch, getGiftDetailsSuccess,getGiftCouponDetailsSuccess } =
  giftSlice.actions;

export const getAllGifts = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/gift/certificate/admin/get/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAllGiftsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getAllCoupons = (setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/gift/certificate/admin/get/coupon/list/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getAllCouponSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getGiftdetails = (id, setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/gift/certificate/admin/get/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    dispatch(getGiftDetailsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const getCouponDetails = (id, setIsLoading) => async (dispatch) => {
  try {
    const url = `${process.env.REACT_APP_API_BASE_URL}/gift/certificate/admin/get/coupon/details/${id}/v1`;
    const { data } = await axios.get(url, {
      headers: {
        Authorization: localStorage.getItem("sgtoken"),
      },
    });
    console.log(data.data)
    dispatch(getGiftCouponDetailsSuccess(data.data));
    setIsLoading(false);
  } catch (error) {
    console.log(error);
    setIsLoading(false);
  }
};

export const deleteGift =
  (id, setIsLoading, setDeleteModal) => async (dispatch) => {
    try {
      dispatch(refetch(false));
      const url = `${process.env.REACT_APP_API_BASE_URL}/gift/certificate/delete/${id}/v1`;
      const { data } = await axios.delete(url, {
        headers: { Authorization: localStorage.getItem("sgtoken") },
      });
      setIsLoading(false);
      setDeleteModal(false);
      dispatch(refetch(true));
      toast.success(data.message, {
        position: "top-center",
        autoClose: 5000,
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      setDeleteModal(false);
    }
  };

  export const createGift =
  (serviceForm, selectedFile, navigate, setIsLoading) => async (dispatch) => {
    try {
      if (selectedFile.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/gift/certificate/upload/gift/certificate/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        serviceForm.giftImage = imgArr[0];
      }

      const urlPre = `${process.env.REACT_APP_API_BASE_URL}/gift/certificate/create/v1`;
      const responsePre = await axios.post(urlPre, serviceForm, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });

      toast.success("Gift created successfully", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Error creating rental", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };
  export const updateGift =
  (id, serviceForm, selectedFile, navigate, setIsLoading) => async (dispatch) => {
    try {
      if (selectedFile.length > 0) {
        const imgArr = [];
        const uploadPromises = selectedFile.map(async (file) => {
          try {
            const urlPre = `${process.env.REACT_APP_API_BASE_URL}/gift/certificate/upload/gift/certificate/image/v1`;
            const imgRes = await axios.post(
              urlPre,
              {
                sFileName: file.name,
                sContentType: file.type,
              },
              {
                headers: {
                  Authorization: localStorage.getItem("sgtoken"),
                },
              }
            );
            const url = imgRes.data.data.sUrl;
            await axios.put(url, file, {
              headers: { "Content-Type": file.type },
            });
            imgArr.push(`${imgRes.data.data.sPath}`);
            return imgRes.data.data.sPath;
          } catch (error) {
            console.error("Error uploading file:", error);
            throw error;
          }
        });

        const uploadedImages = await Promise.all(uploadPromises);
        serviceForm.giftImage = imgArr[0];
      }

      const urlPre = `${process.env.REACT_APP_API_BASE_URL}/gift/certificate/update/${id}/v1`;
      const responsePre = await axios.put(urlPre, serviceForm, {
        headers: {
          Authorization: localStorage.getItem("sgtoken"),
        },
      });

      toast.success("Gift Updated successfully", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
      navigate(-1);
    } catch (error) {
      console.log(error);
      toast.error("Error Updating Gift", {
        position: "top-center",
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

export default giftSlice.reducer;
